<template>
  <page-main
    title="Ready to write your cover letter?"
    aside=""
    nextText="Let's get started!"
    buttonTabIndex="9"
    eventName="nav"
    @nav="nav('/TargetIndustry')"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          (Some text about writing cover letters...)
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>

// @ is an alias to /src
export default {
  name: "CoverLetterIntro",
  data: () => ({}),
  components: {},
  mounted() {},
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {},
};
</script>
